<template>
  <div>
    <vx-card actionable class="cardx" title="Marketing Emails">
      <vs-row>
      <vs-col vs-w="1">
       <vs-radio v-model="work_book" vs-name="mwb" vs-value="mwb">MWB</vs-radio> 
      </vs-col>
      <vs-col vs-w="1">
        <vs-radio v-model="work_book" vs-name="ewb" vs-value="ewb">EWB</vs-radio> 
      </vs-col>
      <vs-col v-if="count != ''" vs-w="2">
        Total Count: {{this.count}}
      </vs-col>
      </vs-row>
      <vs-divider></vs-divider>
      <vs-row style="padding-bottom: 1%">
        <vs-col vs-w="1">Experience :</vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <vs-input
            style="width: 100%"
            placeholder="From"
            v-model="from_experience"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <vs-input
            style="width: 100%"
            placeholder="To"
            v-model="to_experience"
          />
        </vs-col>
        <vs-col vs-w="1">City :</vs-col>
        <vs-col vs-w="2">
          <!-- <v-select
            placeholder="City"
            :multiple="true"
            v-model="selected_city_list"
            :options="city_list"
          ></v-select> -->
          <vs-select
            placeholder="City"
            width="100%"
            multiple
            v-model="selected_city_list"
          >
            <vs-select-item
              :key="index"
              :value="item.text"
              :text="item.text"
              v-for="(item, index) in city_list"
            />
          </vs-select>
        </vs-col>
        <vs-col vs-w="1"> Level :</vs-col>
        <vs-col vs-w="2">
          <!-- <v-select
            placeholder="Level"
            class="w-full"
            :multiple="true"
            v-model="selected_level"
            :options="levels_list"
          ></v-select> -->
          <vs-select
            placeholder="Level"
            width="100%"
            multiple
            v-model="selected_levels"
          >
            <vs-select-item
              :key="index"
              :value="item.text"
              :text="item.text"
              v-for="(item, index) in levels_list"
            />
          </vs-select>
        </vs-col>
      </vs-row>
      <vs-row style="padding-bottom: 1%">
        <vs-col vs-w="1">Age of Lead :</vs-col>
        <vs-col vs-w="2">
          <v-select
            placeholder="From"
            class="w-full"
            v-model="from_age"
            :options="from_age_list"
          ></v-select>
        </vs-col>
        <vs-col vs-w="2">
          <v-select
            placeholder="To"
            class="w-full"
            v-model="to_age"
            :options="to_age_list"
          ></v-select>
        </vs-col>
        <vs-col vs-w="1">Education :</vs-col>
        <vs-col vs-w="2">
          <!-- <v-select
            placeholder="Education"
            class="w-full"
            :multiple="true"
            v-model="selected_education"
            :options="education_tags"
          ></v-select> -->
          <vs-select
            placeholder="Level"
            width="100%"
            multiple
            v-model="selected_education"
          >
            <vs-select-item
              :key="index"
              :value="item.text"
              :text="item.text"
              v-for="(item, index) in education_tags"
            />
          </vs-select>
        </vs-col>
        <vs-col vs-w="1">Course :</vs-col>
        <vs-col vs-w="2">
          <vs-select
            placeholder="Course"
            width="100%"
            multiple
            v-model="selected_course"
          >
            <vs-select-item
              :key="index"
              :value="item.text"
              :text="item.text"
              v-for="(item, index) in courses_list"
            />
          </vs-select>
          <!-- <v-select
            placeholder="Course"
            class="w-full"
            :multiple="true"
            v-model="selected_course"
            :options="courses_list"
          ></v-select> -->
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-w="1">Enrollment :</vs-col>
        <vs-col vs-w="2">
          <v-select
            placeholder="From"
            class="w-full"
            v-model="from_enrollment"
            :options="from_enrollment_list"
          ></v-select>
        </vs-col>
        <vs-col vs-w="2">
          <v-select
            placeholder="To"
            class="w-full"
            v-model="to_enrollment"
            :options="to_enrollment_list"
          ></v-select>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="7">
          <vs-button
            @click="searchData()"
            color="dark"
            type="gradient"
            icon="search"
          ></vs-button>
        </vs-col>
      </vs-row>
      <br />
      <vs-divider></vs-divider>
      <vs-row v-if="show_email_card">
        <vs-col vs-w="2"> Enter email name : </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="2">
          <vs-input
            v-if="show_email_card"
            style="width: 100%"
            placeholder="Enter email name"
            v-model="email_name"
          />
        </vs-col>
        <vs-col vs-w="2">
          <vs-button color="dark" type="gradient" @click="getEmailData()">
            Go
          </vs-button>
        </vs-col>
      </vs-row>
      <br />
      <!-- <vs-row>
        <vs-col vs-justify="center" vs-align="center" vs-w="12">
          <vs-card v-if="show_email_image">
            <vs-row>
              <vs-col vs-justify="center" vs-align="center" vs-w="12">
                <img styel="border: 1px solid #FF0000" :src="get_email_data.thumbnail_url" />
              </vs-col>
            </vs-row>
            <vs-divider></vs-divider>
            <vs-row>
              <vs-col vs-justify="center" vs-align="center" vs-w="12"
                >Are you sure to send this email ?</vs-col
              >
            </vs-row>
            <vs-row>
              <vs-col vs-justify="right" vs-align="right" vs-w="12">
                <vs-button>No</vs-button>
                <vs-button>Yes</vs-button>
              </vs-col>
            </vs-row>
          </vs-card>
        </vs-col>
      </vs-row> -->
      <vs-popup
        class="holamundo"
        id="email_popup_width"
        title="Email image"
        :active.sync="show_email_image_popup"
      >
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <img :src="get_email_data.thumbnail_url" />
          </vs-col>
        </vs-row>
        <vs-divider></vs-divider>
        <vs-row>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="8">
            <b>Following are the filters selected </b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="4">
            <b>Count</b>: {{this.count}}
          </vs-col>
        </vs-row>
        <vs-row v-if="from_experience != null && to_experience != null">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3"
            >Experience :
          </vs-col>
          <vs-col  vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
            {{ from_experience }}-{{ to_experience }}
          </vs-col>
          </vs-row>
          <vs-row v-if="selected_cities != ''">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3">
            City :
          </vs-col>
          <vs-col
            style="overflow-wrap: break-word"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="9"
          >
            {{ selected_cities }}
          </vs-col>
          </vs-row>
          <vs-row v-if="selected_levels_list != ''">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3"
            >Level :
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
            {{ selected_levels_list }}
          </vs-col>
        </vs-row>
        <vs-row v-if="from_age != null && to_age != null">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3"
            >Age of Lead :
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
            {{ from_age }}-{{ to_age }}
          </vs-col>
        </vs-row>
        <vs-row v-if="selected_courses_list != ''">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3"
            >Course :
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
            {{ selected_courses_list }}
          </vs-col>
        </vs-row>
        <vs-row v-if="selected_education_list != ''">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3"
            >Education:
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
            {{ selected_education_list }}
          </vs-col>
        </vs-row>
        <vs-row v-if="from_enrollment != null &&  to_enrollment != null">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3"
            >Enrollment :
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9"
            >{{ from_enrollment }}-{{ to_enrollment }}
          </vs-col>
        </vs-row>
        <br />
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12"
            ><b>Are you sure to send this email ?</b>
          </vs-col>
        </vs-row>
        <br />
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button @click="show_email_image_popup= false">Yes</vs-button>
            <vs-button @click="show_email_image_popup= false"  style="margin-left: 1%">No</vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <br />
      <vs-table :data="table_data">
        <template slot="thead">
          <vs-th>Can-ID</vs-th>
          <vs-th> Experience </vs-th>
          <vs-th> City </vs-th>
          <vs-th> Education </vs-th>
          <!-- <vs-th> Enrollment </vs-th> -->
          <vs-th> Course </vs-th>
          <vs-th> Level </vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].identity">
              {{ data[indextr].identity }}
            </vs-td>
            <vs-td :data="data[indextr].experience_int">
              {{ data[indextr].experience_int }}
            </vs-td>
            <vs-td :data="data[indextr].city">
              {{ data[indextr].city }}
            </vs-td>
            <vs-td :data="data[indextr].education">
              {{ data[indextr].education }}
            </vs-td>
            <!-- <vs-td :data="data[indextr].enrollment">
              {{ data[indextr].enrollment }}
            </vs-td> -->
            <vs-td :data="data[indextr].course">
              {{ data[indextr].course }}
            </vs-td>
            <vs-td :data="data[indextr].level">
              {{ data[indextr].level }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination style="padding-top: 1%;" :total="total_pages"  v-model="current_table_page"></vs-pagination>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
export default {
  components: {
    "v-select": vSelect,
    constants
  },
  data() {
    return {
      current_table_page: 1,
      count: "",
      total_pages: 1,
      work_book: "mwb",
      selected_levels_list: "",
      show_email_image_popup: false,
      show_email_card: false,
      email_name: "",
      table_data: [],
      from_enrollment_list: [],
      from_enrollment: null,
      to_enrollment_list: [],
      to_enrollment: null,
      selected_levels: [],
      levels_list: [
        { text: "All" },
        { text: "M3" },
        { text: "M3+" },
        { text: "M3++" },
        { text: "M5" },
        { text: "M6" },
        { text: "M4-" },
        { text: "M4" },
        { text: "M2" },
        { text: "M1" },
        { text: "M7" },
        { text: "M7-" },
        { text: "M7+" },
        { text: "M7#" },
        { text: "M7-JJ" },
        { text: "M7X" },
        { text: "M7D1" },
        { text: "M7D2" },
        { text: "M8" },
        { text: "M8+" },
        { text: "M8-" },
        { text: "M9" },
        { text: "M9-" },
        { text: "M10" },
        { text: "N/A" },
        { text: "DND" },
      ],
      selected_course: [],
      selected_courses_list: "",
      courses_list: [
        { text: "CPA" },
        { text: "CPA-AA" },
        { text: "CPA-US" },
        { text: "CMA" },
        { text: "CFA" },
        { text: "FRM" },
        { text: "USP" },
        // { text: "AICPA" },
      ],
      selected_education: [],
      selected_education_list: "",
      education_tags: [
        { text: "PUC" },
        { text: "B.Com" },
        { text: "B.Com(P)" },
        { text: "M.Com" },
        { text: "M.Com(P)" },
        { text: "MBA" },
        { text: "MBA(P)" },
        { text: "CA" },
        { text: "CWA" },
        { text: "CS" },
        { text: "BAF" },
        { text: "BMS(P)" },
        { text: "BBA(P)" },
        { text: "PGD(P)" },
        { text: "LLB" },
        { text: "LLB(P)" },
        { text: "B.Tech" },
        { text: "B.Tech(P)" },
        { text: "B.Sc" },
        { text: "B.Sc(P)" },
        { text: "BA" },
        { text: "BA(P)" },
        { text: "CA-Inter" },
        { text: "CWA-Inter" },
        { text: "CS-Inter" },
        { text: "PG(P)" },
        { text: "CA Final" },
        { text: "CWA Final" },
        { text: "CS Final" },
        { text: "IGNOU - B.Com (CA)" },
        { text: "IGNOU - B.Com (CWA)" },
        { text: "IGNOU - B.Com (CS)" },
        { text: "MCA" },
        { text: "PGDPA" },
        { text: "PGDSF" },
        { text: "PGDM" },
        { text: "BBM" },
        { text: "SCESA" },
        { text: "MA" },
        { text: "CFA" },
        { text: "FRM" },
        { text: "CFA(P)" },
        { text: "MFA" },
        { text: "MFA(P)" },
        { text: "CMA(US)" },
        { text: "CPA(US)" },
      ],
      selected_city_list: [],
      city_list: [
        { text: "Ahmedabad" },
        { text: "Bangalore" },
        { text: "Chennai" },
        { text: "Delhi" },
        { text: "Ernakulam" },
        { text: "Hyderabad" },
        { text: "Kolkata" },
        { text: "Mumbai" },
        { text: "Pune" },
        { text: "International" },
        { text: "Global" },
      ],
      today: new Date(),
      from_age: null,
      to_age: null,
      from_age_list: [],
      to_age_list: [],
      from_experience: null,
      to_experience: null,
      email_data: [],
      show_email_image: false,
      get_email_data: {},
      email_response: {},
      selected_cities: "",
    };
  },
  mounted() {
    this.getAgesList();
  },
  watch: {
    selected_course(val) {
      this.selected_courses_list = val.join("|");
    },
    selected_education(val) {
      this.selected_education_list = val.join("|");
    },
    selected_city_list(val) {
      this.selected_cities = val.join(",");
    },
    selected_levels(val) {
      this.selected_levels_list = val.join(",");
    },
  },
  methods: {
    getEmailData() {
      let url = `${constants.MILES_CM_BACK}getSendgridDesignIdFromName?design_name=${this.email_name}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("getSendgridDesignIdFromName", response);
           this.get_email_data = response.data;
         
        })
        .catch((error) => {
          this.handleError(error);
        });
        document.getElementById("email_popup_width").children[1].style.width =
          "100";
        this.show_email_image_popup = true;
    },
    getAgesList() {
      for (let i = 2011; i <= this.today.getFullYear(); i++) {
        const element = i;
        this.from_age_list.push(element);
        this.to_age_list.push(element);
        this.from_enrollment_list.push(element);
        this.to_enrollment_list.push(element);
      }
    },
    searchData() {
      this.$vs.loading();
      this.show_email_card = true;
      let obj = {
        work_book: this.work_book,
        from_experience: this.from_experience,
        to_experience: this.to_experience,
        selected_cities: this.selected_cities,
        selected_levels: this.selected_levels_list,
        from_age: this.from_age,
        to_age: this.from_age,
        selected_educations: this.selected_education_list,
        selected_courses: this.selected_courses_list,
        from_enrollment: this.from_enrollment,
        to_enrollment: this.to_enrollment,
      };
      console.log("search data", obj);
      let url = `${constants.MILES_CM_BACK}getLeadsCountForEmailCampaign?page=${this.current_table_page}`;
      axios
        .post(url,obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.$vs.loading.close();
          console.log("getLeadsCountForEmailCampaign", response.data);
          this.table_data = response.data.data;
          this.total_pages = response.data.last_page;
          this.count = response.data.total;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style>
</style>
